<section id="references">
  <div class="container mb-5">
    <h2><strong>Recommendations</strong></h2>
    <span class="bar-title mb-5"></span>
    <div
      class="card shadow-lg rounded pb-3"
      data-aos="fade-right"
      data-aos-delay="300"
    >
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="not-active active"
          ></li>
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="1"
            class="not-active"
          ></li>
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="2"
            class="not-active"
          ></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="d-block w-80 ml-4 mr-4">
              <div class="row">
                <div class="col-lg-2 ml-4 mr-4 col-sm-12">
                  <div class="row">
                    <div class="mt-4">
                      <img
                        class="d-block w-100 rounder"
                        src="https://images.squarespace-cdn.com/content/v1/5c2ed7a5b10598bd61638caa/1567170298450-CAS582J5QAWLLLVNZYPV/BradLibrarySteps.jpeg"
                        alt="Second slide"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="mt-3">
                      <h5>Brad Byrd</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mt-2">
                      <h5><small>Coach, Literary Agent</small></h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mt-2">
                      <span class="text-uppercase"
                        ><strong>Wadestone, Inc.</strong></span
                      >
                    </div>
                  </div>
                </div>
                <div class="card-body ml-4 mr-3 mt-4 col-lg-9 col-sm-12">
                  <div class="row">
                    <p>
                      When I needed someone to develop the technical side of
                      <a href="https://www.podspotdigital.com">Podspot</a> where
                      I sell advertising for podcast networks, Paul and
                      PROBsolved.dev did a masterful job. He listened carefully
                      to my needs, sought to understand my business model, and
                      went above and beyond in creating a site that looks and
                      functions exactly how I wanted. He communicated with me
                      regularly and in ways that I found convenient.
                    </p>
                    <br />

                    <p>
                      He took care of everything and continues to eagerly
                      support my company. I highly recommend!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <!-- <div class="carousel-item">
            <div class="d-block w-100">
              <div class="row">
                <div class="col-lg-2 ml-4 mr-4 col-sm-12">
                  <div class="row">
                    <div class="mt-4">
                      <img
                        class="d-block w-100 rounder"
                        src="https://peggthomas.files.wordpress.com/2022/01/pegg-spinning-2021-at-besser-senior-living.jpg"
                        alt="Second slide"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="mt-3">
                      <h5>Pegg Thomas</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mt-2">
                      <h5><small>Author</small></h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mt-2">
                      <span class="text-uppercase"
                        ><strong>Spinner of Yarns</strong></span
                      >
                    </div>
                  </div>
                </div>
                <div class="card-body ml-4 mr-3 mt-4 col-lg-9 col-sm-12">
                  <div class="row">
                    <span
                      >Est before they sold out bologna tbh, iceland fanny pack
                      portland tofu consectetur. 3 wolf moon chambray selvage
                      PBR&B minim farm-to-table. Gochujang la croix fanny pack
                      vegan selfies brunch. Activated charcoal eu schlitz
                      typewriter poutine qui nulla, photo booth chillwave
                      exercitation craft beer franzen irure.
                    </span>
                    <br />
                    <p>
                      Est before they sold out tbh, iceland fanny pack portland
                      tofu consectetur. 3 wolf moon chambray selvage PBR&B minim
                      farm-to-table. Gochujang la croix fanny pack vegan selfies
                      brunch. Activated charcoal eu schlitz typewriter poutine
                      qui nulla, photo booth chillwave exercitation craft beer
                      franzen irure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!--  -->
          <div class="carousel-item">
            <div class="d-block w-80 ml-4 mr-4">
              <div class="row">
                <div class="col-lg-2 ml-4 mr-4 col-sm-12">
                  <div class="row">
                    <div class="mt-4">
                      <img
                        class="d-block w-100 rounder"
                        src="https://www.se.edu/wp-content/uploads/fly-images/8311/David-Whitlock-280x280-ct.jpg"
                        alt="Second slide"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="mt-3">
                      <h5>David Whitlock</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mt-2">
                      <h5>
                        <small
                          >University President, Business Professor,
                          Speaker</small
                        >
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mt-2">
                      <span class="text-uppercase"><strong></strong></span>
                    </div>
                  </div>
                </div>
                <div class="card-body ml-4 mr-3 mt-4 col-lg-9 col-sm-12">
                  <div class="row">
                    <p>
                      Paul brought vision, leadership, and positivity to our
                      team as the university's VP/CIO and Dean of Libraries. His
                      overhaul of library technolologies and search platforms
                      had a pervasive influence. His ability to see the benefits
                      of integrated information across siloed departments
                      resulted in new perspectives on what was possible for us.
                      He fostered connections and opportunities for our staff
                      and students through collaborations with both internal and
                      external clientele.
                    </p>
                    <br />
                    <p>And, he was a joy to work with.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="jumbotron">
      The most efficient method of contact:
      <div class="pr-contact">
        <i class="fa fa-envelope"></i>

        <a href="mailto:paul@probsolved.dev">paul@probsolved.dev</a>
      </div>
    </div> -->
  </div>
</section>
