<section id="portfolio">
  <div class="container">
    <h2><strong>Portfolio</strong></h2>

    <!-- Podspot Digital -->
    <!-- Card Header -->
    <span class="bar-title mb-5"></span>
    <div class="row">
      <!-- Podspot -->
      <div class="col-lg-4 col-sm-12" data-aos="flip-up">
        <div class="card shadow-lg p-3 mb-5 bg-white rounded">
          <div
            class="card-header d-flex flex-column"
            style="height: 300px; width: 100%"
          >
            <div
              id="mautic-img"
              class="img-wrap"
              style="height: 300px; width: 100%"
              [ngStyle]="{
                backgroundImage: 'url(assets/images/podspot.png'
              }"
            ></div>
            <div
              id="mautic-link"
              class="links mt-auto d-flex justify-content-center"
            >
              <a
                href="assets/images/podspot.png"
                data-gall="MauticGallery"
                class="venobox vbox-item"
                title="Podspot Digital"
                ><i class="bx bx-zoom-in bx-md"></i
              ></a>
              <a
                href="assets/images/podspot2.png"
                data-gall="MauticGallery"
                class="venobox vbox-item"
                title="Podspot Digital"
              ></a>

              <a href="#portfolio" title="En cours" target="_BLANK"
                ><i class="bx bx-link bx-md"></i
              ></a>
            </div>
          </div>
          <div class="card-body">
            <h3 class="card-title">Podspot</h3>
            <h5 class="card-title"><strong>Technologies:</strong></h5>
            <p class="card-text">
              Wordpress, Elementor, CSS, JavaScript, Bluehost, Corporate Email
            </p>
            <div class="d-flex flex-row justify-content-center">
              <a
                href="https://www.podspotdigital.com"
                target="_blank"
                type="button"
                class="btn btn-primary mr-3"
                >Live Site</a
              >
              <button
                (click)="detailOnClick('podspot')"
                type="button"
                class="btn btn-primary"
              >
                + Project Details
              </button>
            </div>
            <div *ngIf="podspotIsShown" class="mt-3">
              <p>
                Podspot connects creators, podcasters, advertisers, and
                audiobook publishers with each other and with their audiences.
              </p>
              <p>
                The sales of digital advertisement is a fast-evolving world.
                Podspot provides a means to accomplish this in a way that
                enables creators and their networks to focus on their content
                instead of the marketing.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-12" data-aos="flip-up">
        <div class="card shadow-lg p-3 mb-5 bg-white rounded">
          <div
            class="card-header d-flex flex-column"
            style="height: 300px; width: 100%"
          >
            <div
              id="teamTraveler-img"
              class="img-wrap"
              style="height: 300px; width: 100%"
              [ngStyle]="{
                backgroundImage: 'url(assets/images/mylibrarian.png)'
              }"
            ></div>
            <div
              id="teamTraveler-link"
              class="links mt-auto d-flex justify-content-center"
            >
              <a
                href="assets/images/mylibrarian2.png"
                data-gall="teamTravelerGallery"
                class="venobox vbox-item"
                title="Mes Voyages"
                ><i class="bx bx-zoom-in bx-md"></i
              ></a>
              <a
                href="assets/images/mylibrarian3.png"
                data-gall="teamTravelerGallery"
                class="venobox vbox-item"
                title="Détail du voyage"
              ></a>
              <a
                href="assets/images/mylibrarian4.png"
                data-gall="teamTravelerGallery"
                class="venobox vbox-item"
                title="Mes Logements"
              ></a>
              <a href="" title="" target="_BLANK"
                ><i class="bx bx-link bx-md"></i
              ></a>
            </div>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <h3 class="card-title">MyLibrarian Online</h3>
            <h5 class="card-title"><strong>Technologies:</strong></h5>
            <p class="card-text">
              Wordpress, PHP, CSS, JavaScript, Premiere Pro, After Effects
            </p>
            <div class="d-flex flex-row justify-content-center">
              <a
                href="https://www.mylibrarian.online"
                target="_blank"
                target="_blank"
                type="button"
                class="btn btn-primary mr-3"
                >Live Site</a
              >
              <button
                (click)="detailOnClick('teamTraveler')"
                type="button"
                class="btn btn-primary"
              >
                + Project Details
              </button>
            </div>
            <div *ngIf="teamTravelerIsShown" class="mt-3">
              <p>
                This e-commerce application provides free and paid access to
                online courses with faceted browsing, forums, live chat, and
                online bookings. This application uses custom CSS, JavaScript,
                external services, and server-side databases.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Check It Out Course Platform -->
      <div class="col-lg-4 col-sm-12" data-aos="flip-up">
        <div class="card shadow-lg p-3 mb-5 bg-white rounded">
          <div
            class="card-header d-flex flex-column"
            style="height: 300px; width: 100%"
          >
            <div
              id="mautic-img"
              class="img-wrap"
              style="height: 300px; width: 100%"
              [ngStyle]="{
                backgroundImage: 'url(assets/images/checkitout.png'
              }"
            ></div>
            <div
              id="mautic-link"
              class="links mt-auto d-flex justify-content-center"
            >
              <a
                href="assets/images/checkitout.png"
                data-gall="MauticGallery"
                class="venobox vbox-item"
                title="Check It Out! Course Delivery Platform"
                ><i class="bx bx-zoom-in bx-md"></i
              ></a>
              <a
                href="assets/images/courses1.png"
                data-gall="MauticGallery"
                class="venobox vbox-item"
                title="Check It Out! Course Delivery Platform"
              ></a>
              <a
                href="assets/images/courses2.png"
                data-gall="MauticGallery"
                class="venobox vbox-item"
                title="Performance d'un émail"
              ></a>

              <a href="#portfolio" title="En cours" target="_BLANK"
                ><i class="bx bx-link bx-md"></i
              ></a>
            </div>
          </div>
          <div class="card-body">
            <h3 class="card-title">Check It Out! Course Delivery Platform</h3>
            <h5 class="card-title"><strong>Technologies:</strong></h5>
            <p class="card-text">PHP, JavaScript, TypeScript, Moodle</p>
            <div class="d-flex flex-row justify-content-center">
              <a
                href="https://courses.mylibrarian.online"
                target="_blank"
                type="button"
                class="btn btn-primary mr-3"
                >Live Site</a
              >
              <button
                (click)="detailOnClick('mauticMonitoring')"
                type="button"
                class="btn btn-primary"
              >
                + Project Details
              </button>
            </div>
            <div *ngIf="mauticMonitoringIsShown" class="mt-3">
              <p>
                Check It Out! is a full-featured online course delivery
                platform.
              </p>
              <p>
                Students and instructors access their courses through
                customizable dashboards. Course content, assignments, and
                deadlines are collated into a unified calendar and reminder
                system.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Pediatric Speech Therapy -->
      <!-- Card Header -->
      <div class="col-lg-4 col-sm-12" data-aos="flip-up">
        <div class="card shadow-lg p-3 bg-white rounded">
          <div
            class="card-header d-flex flex-column"
            style="height: 300px; width: 100%"
          >
            <div
              id="pokedex-img"
              class="img-wrap"
              style="height: 300px; width: 100%"
              [ngStyle]="{
                backgroundImage: 'url(assets/images/latitude2.png)'
              }"
            ></div>
            <div
              id="pokedex-link"
              class="links mt-auto d-flex justify-content-center"
            >
              <a
                href="assets/images/latitude.png"
                data-gall="pokedexGallery"
                class="venobox vbox-item"
                title="Pokedex"
                ><i class="bx bx-zoom-in bx-md"></i
              ></a>
              <a href="#portfolio" title="Indisponible"
                ><i class="bx bx-link bx-md"></i
              ></a>
            </div>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <h3 class="card-title">Pediatric Speech Therapy</h3>
            <h5 class="card-title"><strong>Technologies:</strong></h5>
            <p class="card-text">Wordpress, CSS, JavaScript</p>
            <div class="d-flex flex-row justify-content-center">
              <a
                href="https://www.latitudepediatrictherapy.com"
                target="_blank"
                type="button"
                class="btn btn-primary mr-3"
                >Live Site</a
              >
              <button
                (click)="detailOnClick('pokedex')"
                type="button"
                class="btn btn-primary"
              >
                + Project Details
              </button>
            </div>
            <div *ngIf="pokedexIsShown" class="mt-3">
              <p>A corporate website for a professional therapy company.</p>
              <p>
                This application provides basic information to their clients:
                photos, maps, contact information, forms, and alert
                notifications. Note, this site has been adapted by the client.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Get Stumped Trivia  -->
      <!-- Card Header -->
      <div class="col-lg-4 col-sm-12" data-aos="flip-up">
        <div class="card shadow-lg p-3 mb-5 bg-white rounded">
          <div
            class="header d-flex flex-column"
            style="height: 300px; width: 100%"
          >
            <div
              id="portfolio-img"
              class="img-wrap card-header"
              style="height: 300px; width: 100%"
              [ngStyle]="{
                backgroundImage: 'url(assets/images/stumped2.png)'
              }"
            ></div>
            <div
              id="portfolio-link"
              class="links d-flex justify-content-center"
            >
              <a
                href="assets/images/stumped.png"
                data-gall="portfolioGallery"
                class="venobox vbox-item"
                title="Portfolio"
                ><i class="bx bx-zoom-in bx-md"></i
              ></a>
            </div>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <h3 class="card-title">Stumped! Trivia App</h3>
            <h5 class="card-title"><strong>Technologies:</strong></h5>
            <p class="card-text">HMTL5, CSS3</p>

            <div class="d-flex flex-row justify-content-center">
              <a
                href="https://github.com/probsolved/Stumped-Trivia-App"
                target="_blank"
                type="button"
                class="btn btn-primary mr-3"
                >Code Source</a
              >
              <button
                (click)="detailOnClick('portfolio')"
                type="button"
                class="btn btn-primary"
              >
                + Project Details
              </button>
            </div>

            <div *ngIf="portfolioIsShown" class="mt-3">
              <p>
                This pet project is an example of a purely HTML, CSS, and
                javascript project that utilizes an open API for trivia
                questions and answers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Bracketology -->
      <!-- Card Header -->
      <div class="col-lg-4 col-sm-12" data-aos="flip-up">
        <div class="card shadow-lg p-3 mb-5 bg-white rounded">
          <div
            class="header d-flex flex-column"
            style="height: 300px; width: 100%"
          >
            <div
              id="riddle-img"
              class="img-wrap card-header"
              style="height: 300px; width: 100%"
              [ngStyle]="{
                backgroundImage: 'url(assets/images/brackets1.png)'
              }"
            ></div>
            <div id="riddle-link" class="links d-flex justify-content-center">
              <a
                href="assets/images/brackets2.png"
                data-gall="riddleGallery"
                class="venobox vbox-item"
                title="riddle"
                ><i class="bx bx-zoom-in bx-md"></i
              ></a>
            </div>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <h3 class="card-title">Bracketology</h3>
            <h5 class="card-title"><strong>Technologies:</strong></h5>
            <p class="card-text">HTML5, CSS3, JavaScript</p>

            <div class="d-flex flex-row justify-content-center">
              <a
                href="https://github.com/probsolved/Bracketology"
                target="_blank"
                type="button"
                class="btn btn-primary mr-3"
                >Code Source</a
              >
              <button
                (click)="detailOnClick('riddleIsShown')"
                type="button"
                class="btn btn-primary"
              >
                + Project Details
              </button>
            </div>

            <div *ngIf="riddleIsShown" class="mt-3">
              <p>
                This is another pet project. Purely an HTML and CSS project at
                the moment, the roadmap includes allowing users to select their
                teams. Updates to games and scores will be provided through an
                API call.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- SuperUser Do! -->
      <!-- Card Header -->
      <div class="col-lg-4 col-sm-12" data-aos="flip-up">
        <div class="card shadow-lg p-3 mb-5 bg-white rounded">
          <div
            class="header d-flex flex-column"
            style="height: 300px; width: 100%"
          >
            <div
              id="sudo-img"
              class="img-wrap card-header"
              style="height: 300px; width: 100%"
              [ngStyle]="{
                backgroundImage: 'url(assets/images/sudotasks-logo.png)'
              }"
            ></div>
            <div id="sudo-link" class="links d-flex justify-content-center">
              <a
                href="assets/images/sudotasks-logo.png"
                data-gall="riddleGallery"
                class="venobox vbox-item"
                title="riddle"
                ><i class="bx bx-zoom-in bx-md"></i
              ></a>
            </div>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <h3 class="card-title">SuperUser Do!</h3>
            <h5 class="card-title"><strong>Technologies:</strong></h5>
            <p class="card-text">
              Angular 14, HTML5, CSS3, JavaScript, TypeScript
            </p>

            <div class="d-flex flex-row justify-content-center">
              <a
                href="https://github.com/probsolved/sudoTasks"
                target="_blank"
                type="button"
                class="btn btn-primary mr-3"
                >Code Source</a
              >
              <button
                (click)="detailOnClick('sudoIsShown')"
                type="button"
                class="btn btn-primary"
              >
                + Project Details
              </button>
            </div>

            <div *ngIf="sudoIsShown" class="mt-3">
              <p>
                This application utilizes the Angular framework to create a
                projects/tasks application that also makes use of the Angular
                Calendar component.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!--  -->
      <!-- Met With Me-->
      <!-- Card Header -->
      <div class="col-lg-4 col-sm-12" data-aos="flip-up">
        <div class="card shadow-lg p-3 mb-5 bg-white rounded">
          <div
            class="header d-flex flex-column"
            style="height: 300px; width: 100%"
          >
            <div
              id="met-img"
              class="img-wrap card-header"
              style="height: 300px; width: 100%"
              [ngStyle]="{
                backgroundImage: 'url(assets/images/artboard_6.png)'
              }"
            ></div>
            <div id="met-link" class="links d-flex justify-content-center">
              <a
                href="assets/images/artboard_6png"
                data-gall="metGallery"
                class="venobox vbox-item"
                title="met"
                ><i class="bx bx-zoom-in bx-md"></i
              ></a>
            </div>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <h3 class="card-title">The Met. With Me.</h3>
            <h5 class="card-title"><strong>Technologies:</strong></h5>
            <p class="card-text">
              Angular 14, HTML5, CSS3, JavaScript, TypeScript
            </p>

            <div class="d-flex flex-row justify-content-center">
              <a
                href="https://github.com/sjohnsonpbh/met-with-me"
                target="_blank"
                type="button"
                class="btn btn-primary mr-3"
                >Code Source</a
              >
              <button
                (click)="detailOnClick('metIsShown')"
                type="button"
                class="btn btn-primary"
              >
                + Project Details
              </button>
            </div>

            <div *ngIf="metIsShown" class="mt-3">
              <p>
                This group project application provides an alternative search
                GUI to the Metropolitan Museum of Art. I coded the API search,
                footer, podcast, and popular art components.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!--  -->
    </div>
  </div>
</section>
