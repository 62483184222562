<section id="contact">
  <div class="container">
    <h2><strong>Contact Me</strong></h2>
    <span class="bar-title"></span>
    <br />
    <br />

    <div class="jumbotron">
      <!--Section: Contact v.2-->

      <!--Section heading-->
      <h2 class="h1-responsive font-weight-bold text-center">
        Ask a question. Say hello.
      </h2>
      <!--Section description-->
      <p class="text-center w-responsive mx-auto mb-5">
        We would love the opportunity to interact with you and even help you
        think through a few ideas. <br />
        It all starts with an email. What are you waiting for?
      </p>
      <div class="text-center">
        <i class="fa fa-map-marker fa-2x"></i>
        <p>
          PROBsolved Web Dev / Paul Roberts<br />338 Broadway, Suite 608<br />Cape
          Girardeau, MO 63701
        </p>

        <i class="fa fa-envelope mt-4 fa-2x"></i>
        <p>
          <a href="mailto:paul@probsolved.dev">paul@probsolved.dev</a>
        </p>
      </div>
    </div>
  </div>
</section>
