<section id="contact">
  <div class="container mb-5">
    <h2><strong>Contact Me</strong></h2>
    <span class="bar-title mb-5"></span>
    <div class="row" data-aos="fade-up" data-aos-anchor-placement="top-center">
      <div class="col-md-3 col-lg-3">
        <div class="contact-info">
          <img src="assets/images/contact-image.png" alt="image" />
          <h4>Contact me. Or even hire me.</h4>
        </div>
      </div>
      <div class="col-md-9">
        <div class="contact-form">
          <div class="form-group">
            <label class="control-label col-sm-2" for="identite">Name:</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                id="identite"
                placeholder="Name"
                name="fname"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="control-label col-sm-2" for="email">E-mail:</label>
            <div class="col-sm-10">
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Email address"
                name="email"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="control-label col-sm-2" for="object">Subject:</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                id="object"
                placeholder="Subject"
                name="object"
              />
            </div>
          </div>

          <div class="form-group">
            <label class="control-label col-sm-2" for="comment">Message:</label>
            <div class="col-sm-10">
              <textarea class="form-control" rows="5" id="comment"></textarea>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-offset-2 col-sm-10">
              <button type="submit" class="btn btn-default">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
