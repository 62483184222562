<section id="cv">
  <div class="container">
    <h2><strong>Curriculum Vitae</strong></h2>
    <span class="bar-title mb-5"></span>
    <div class="row">
      <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
        <h4><strong>Education</strong></h4>

        <ul class="timeline">
          <li>
            <h5><strong>CodeLabs</strong></h5>
            <em class="font-italic"
              >Front-End, Back-End<br />
              CodeLabs, Codefi</em
            >
            <p class="year p-2 d-flex justify-content-center">2022-2023</p>
          </li>
          <li>
            <h5>Master of Science</h5>
            <em class="font-italic"
              >School of Information Science <br />
              University of Kentucky</em
            >
            <p class="year p-2 d-flex justify-content-center">2010</p>
          </li>
          <li>
            <h5>PhD Research Studies</h5>
            <em class="font-italic"
              >Theologische Universiteit de Gereformeerde Kerken <br />
              Kampen, Netherlands</em
            >
            <p class="year p-2 d-flex justify-content-center">2011-2015</p>
          </li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
        <h4><strong>Experience</strong></h4>
        <ul class="timeline">
          <li>
            <h5>Freelance Developer</h5>
            <em>Missouri, USA</em>
            <div class="d-flex flex-row">
              <p class="year p-2 d-flex justify-content-center">2020 - 2022</p>
              <button
                (click)="detailOnClick('freelanceScotfy_detail')"
                type="button"
                class="btn btn-primary"
              >
                + Detail
              </button>
            </div>
            <p *ngIf="freelanceScotfyIsShown">
              <strong>Task 1:</strong> Develop Wordpress sites <br />
              <strong>Task 2:</strong> Photography for sites, including the use
              of mirrorless SLRs, Photoshop, Lightroom.<br />
              <strong>Task 3:</strong> Develop graphics for sites and logos,
              including the use of After Effects, Premiere Pro, Adobe
              Illustrator.<br />
              <strong>Task 4:</strong> Creation of print catalogs to coordinate
              with online catalogs, including the use of Adobe InDesign.<br />
              <strong>Technologies:</strong> After Effects, Premiere Pro,
              Illustrator, InDesign, Photoshop, Lightroom
            </p>
          </li>
          <li>
            <h5>Freelance Developer</h5>
            <em>Oklahoma, USA</em>
            <div class="d-flex flex-row">
              <p class="year p-2 d-flex justify-content-center">2018-2020</p>
              <button
                (click)="detailOnClick('alternance_detail')"
                type="button"
                class="btn btn-primary"
              >
                + Detail
              </button>
            </div>
            <p *ngIf="alternanceIsShown">
              <strong>Task 1:</strong> Develop Wordpress sites <br />
              <strong>Task 2:</strong> Photography for sites, including the use
              of mirrorless SLRs, Photoshop, Lightroom.<br />
              <strong>Task 3:</strong> Develop graphics for sites and logos,
              including the use of After Effects, Premiere Pro, Adobe
              Illustrator.<br />
              <strong>Task 4:</strong> Creation of print catalogs to coordinate
              with online catalogs, including the use of Adobe InDesign.<br />
              <strong>Technologies:</strong> After Effects, Premiere Pro,
              Illustrator, InDesign, Photoshop, Lightroom
            </p>
          </li>
          <li>
            <h5>University Vice President & CIO, Dean of Library Services</h5>
            <em>Oklahoma, USA</em>
            <div class="d-flex flex-row">
              <p class="year p-2 d-flex justify-content-center">2012-2019</p>
              <button
                (click)="detailOnClick('freelance_detail')"
                type="button"
                class="btn btn-primary"
              >
                + Detail
              </button>
            </div>
            <p *ngIf="freelanceIsShown">
              <strong>Mission 1:</strong> Supervise campus Information Systems
              and Services<br />
              <strong>Mission 2:</strong> Lead and supervise campus libraries<br />
              <strong>Mission 2:</strong> Supervise campus Creative
              Technologies<br />
            </p>
          </li>

          <li>
            <h5>Director of Patron Services & Systems Coordinator</h5>
            <em>Louisville, KY</em>
            <div class="d-flex flex-row">
              <p class="year p-2 d-flex justify-content-center">2005-2012</p>
              <button
                (click)="detailOnClick('syscoord_detail')"
                type="button"
                class="btn btn-primary"
              >
                + Detail
              </button>
            </div>
            <p *ngIf="sysCoordIsShown">
              <strong>Task 1:</strong> Direct the research and information
              services for a research-level academic library. <br />
              <strong>Task 2:</strong> Coordinate technologies and databases for
              patron use.<br />
              <strong>Task 3:</strong> Create and deliver end-user training on
              effective use of technology and databases for federated
              information discovery and retrieval.<br />
              <strong>Technologies:</strong> Aleph, SFX, Primo
            </p>
          </li>
        </ul>
      </div>
      <div
        class="col-lg-4 col-md-12 aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <!-- <div class="container row">
          <div
            class="aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="300"
          > -->
        <!-- <h4><strong>Langues</strong></h4>
            <div class="noProgress langue">
              <span class=""
                ><img
                  src="assets/images/france-flag.png"
                  width="20"
                  alt="drapeau de la france"
                /><strong> Français</strong> <i class="val"> Courant</i></span
              >
            </div> -->

        <!-- <div class="noProgress langue">
              <span class=""
                ><img
                  src="assets/images/united-kingdom-flag.png"
                  width="20"
                  alt="drapeau du royaume uni"
                /><strong> Anglais</strong> <i class="val"> Bon niveau</i></span
              >
            </div> -->
        <!-- </div>
        </div> -->
        <div class="container row">
          <div
            class="mt-3 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <h4><strong>Interests</strong></h4>
            <div class="noProgress langue">
              <span>
                <fa-icon [icon]="volleyBallIcon"></fa-icon
                ><strong>Sports: </strong> <i class="val">Baseball, Fishing</i>
              </span>
            </div>

            <div class="noProgress langue">
              <span>
                <fa-icon [icon]="planeIcon"></fa-icon><strong> Travel: </strong>
                <i class="val">
                  Netherlands, Germany, Switzerland, Mexico, Guatemala,
                  Canada</i
                >
              </span>
            </div>

            <!-- <div class="noProgress langue">
              <span>
                <fa-icon [icon]="gameIcon"></fa-icon
                ><strong> Jeux de sociétés </strong>
                <i class="val">
                  Jeu de dames et de dominos, Among US, Ludo, ..etc</i
                >
              </span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
