<section id="competences">
  <div class="container">
    <h2><strong>Competencies</strong></h2>
    <span class="bar-title mb-5"></span>
    <div class="row mt-2">
      <div
        class="col-lg-4"
        data-aos="fade-up "
        data-aos-anchor-placement="center-bottom"
        data-aos-delay="300"
      >
        <h5 class="h5"><strong>Languages and Frameworks</strong></h5>
        <div id="skillgraph">
          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">JavaScript/TypeScript</span>
              <span class="skill-percent">70%</span>
            </div>
            <span class="skillData-Wrapper">
              <span class="good skillData bg-rust"></span
            ></span>
          </div>
          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">HTML/CSS</span>
              <span class="skill-percent">90%</span>
            </div>
            <span class="skillData-Wrapper">
              <span class="excellent skillData bg-rust"></span
            ></span>
          </div>
          <div class="row skill-row">
            <!-- <div class="label d-flex flex-row">
              <span class="skillLabel">Ancient Greek, Latin, Hebrew</span>
              <span class="skill-percent">80%</span>
            </div> -->

            <span class="skillData-Wrapper">
              <span class="very-good skillData bg-rust"></span
            ></span>
          </div>
          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">Angular</span>
              <span class="skill-percent">70%</span>
            </div>
            <span class="skillData-Wrapper">
              <span class="excellent skillData bg-rust"></span
            ></span>
          </div>
          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">LaTeX, BibTeX</span>
              <span class="skill-percent">90%</span>
            </div>
            <span class="skillData-Wrapper">
              <span class="excellent skillData bg-rust"></span
            ></span>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4"
        data-aos="fade-down"
        data-aos-anchor-placement="center-bottom"
        data-aos-delay="300"
      >
        <h5 class="h5"><strong>Tools</strong></h5>
        <div id="skillgraph" class="panel panel-default">
          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">Git</span>
              <span class="skill-percent">90%</span>
            </div>
            <span class="skillData-Wrapper">
              <span class="excellent skillData bg-rust"></span
            ></span>
          </div>
          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">Office</span>
              <span class="skill-percent">90%</span>
            </div>

            <span class="skillData-Wrapper">
              <span class="excellent skillData bg-rust"></span
            ></span>
          </div>

          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">Linux, Windows</span>
              <span class="skill-percent">85%</span>
            </div>

            <span class="skillData-Wrapper">
              <span class="very-good skillData bg-rust"></span
            ></span>
          </div>
          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">Visual Studio Code</span>
              <span class="skill-percent">90%</span>
            </div>

            <span class="skillData-Wrapper">
              <span class="excellent skillData bg-rust"></span
            ></span>
          </div>
          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel"
                >Premiere Pro, After Effects, InDesign ...</span
              >
              <span class="skill-percent">80%</span>
            </div>
            <span class="skillData-Wrapper">
              <span class="average skillData bg-rust"></span
            ></span>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
        data-aos-delay="300"
      >
        <h5 class="h5"><strong>Reading Languages</strong></h5>
        <div id="skillgraph" class="panel panel-default">
          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">Biblical Greek</span>
              <span class="skill-percent">80%</span>
            </div>
            <span class="skillData-Wrapper">
              <span class="good skillData bg-rust"></span
            ></span>
          </div>

          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">Biblical Hebrew</span>
              <span class="skill-percent">70%</span>
            </div>

            <span class="skillData-Wrapper">
              <span class="excellent skillData bg-rust" data-percent="90"></span
            ></span>
          </div>
          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">Latin</span>
              <span class="skill-percent">70%</span>
            </div>

            <span class="skillData-Wrapper">
              <span class="good skillData bg-rust"></span
            ></span>
          </div>
          <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">French</span>
              <span class="skill-percent">70%</span>
            </div>

            <span class="skillData-Wrapper">
              <span class="excellent skillData bg-rust"></span
            ></span>
          </div>
          <!-- <div class="row skill-row">
            <div class="label d-flex flex-row">
              <span class="skillLabel">DevOps</span>
              <span class="skill-percent">50%</span>
            </div>
            <span class="skillData-Wrapper">
              <span class="average skillData bg-rust"></span
            ></span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
