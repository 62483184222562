<header>
  <nav class="navbar fixed-top navbar-expand-lg navbar-dark">
    <div class="home p-1 rounded">
      <ul class="navbar-nav">
        <li class="nav-item p-1 rounded">
          <a class="nav-link smooth-scroll text-uppercase" href="#"
            ><i class="bx bx-home"></i> Home
          </a>
        </li>
      </ul>
    </div>
    <button class="navbar-toggler nav mb-1">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="container">
      <div
        style="width: 100%; float: right"
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav" style="float: right">
          <li class="nav-item p-1 rounded">
            <a
              class="nav-link js-scroll-trigger smooth-scroll text-uppercase"
              href="#about"
              ><i class="bx bx-user"></i> About Me
            </a>
          </li>
          <li class="nav-item p-1 rounded">
            <a
              class="nav-link js-scroll-trigger smooth-scroll text-uppercase"
              href="#competences"
              ><i class="bx bx-briefcase-alt"></i>Competencies</a
            >
          </li>
          <li class="nav-item p-1 rounded">
            <a
              class="nav-link js-scroll-trigger smooth-scroll text-uppercase"
              href="#cv"
            >
              <i class="bx bx-file-blank"></i>CV</a
            >
          </li>

          <li class="nav-item p-1 rounded">
            <a
              class="nav-link js-scroll-trigger smooth-scroll text-uppercase"
              href="#portfolio"
            >
              <i class="bx bx-book-content"></i>Portfolio</a
            >
          </li>

          <li class="nav-item p-1 rounded">
            <a
              class="nav-link js-scroll-trigger smooth-scroll text-uppercase"
              href="#references"
              ><i class="bx bxs-badge-check"></i> Recommendations</a
            >
          </li>

          <li class="nav-item p-1 rounded">
            <a
              class="nav-link js-scroll-trigger smooth-scroll text-uppercase"
              href="#contact"
            >
              <i class="bx bx-envelope"></i>Contact Me</a
            >
          </li>
          <li class="nav-item p-1 rounded">
            <a
              class="nav-link js-scroll-trigger smooth-scroll text-uppercase"
              href="https://office.probsolved.dev"
              target="_blank"
            >
              <i class="bx bx-user"></i>Client Login</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
