<section id="about">
  <div class="container">
    <h2><strong>About Me</strong></h2>
    <span class="bar-title mb-5"></span>
    <div class="row">
      <div class="col-lg-4 col-md-12" data-aos="fade-right">
        <picture>
          <img
            src="assets/images/probsolved-paulroberts.jpg"
            alt="probselved logo"
            class="img-fluid"
          />
        </picture>
        <div class="d-flex justify-content-center">
          <a
            class="btn-social-media"
            href="https://www.twitter.com/zee699"
            rel="tooltip"
            target="_blank"
            title="Twitter"
          >
            <i class="fa fa-twitter fa-lg"></i>
          </a>
          <a
            class="btn-social-media"
            href="https://www.fb.com/probsolved.dev"
            rel="tooltip"
            target="_blank"
            title="Facebook"
          >
            <i class="fa fa-facebook fa-lg"></i>
          </a>
          <a
            class="btn-social-media"
            href="https://www.linkedin.com/in/paul-roberts-709682190/"
            rel="tooltip"
            target="_blank"
            title="LinkedIn"
          >
            <i class="fa fa-linkedin fa-lg"></i>
          </a>
          <a
            class="btn-social-media btn-default btn-round btn-lg btn-icon"
            href="https://github.com/probsolved"
            rel="tooltip"
            target="_blank"
            title="Visit me on Github"
          >
            <i class="fa fa-github fa-lg"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 pt-4 pt-lg-0" data-aos="fade-left">
        <h3>I develop Angular web apps and Wordpress sites.</h3>
        <p class="font-italic">
          I am interested in the development of robust but light-weight web
          applications that enable my clients to harness the web to do new
          things. I believe in community, collaboration, and ubiquitous access
          to information.
        </p>
        <p>
          I enjoy and employ <b>Back-End</b> and <b>Front-End</b> development
          techniques to meet the needs of my clients.
        </p>
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <ul>
              <li>
                <fa-icon [icon]="mapMarker"></fa-icon
                ><strong>The Web is worldwide. So am I.</strong>
              </li>
              <li>
                <fa-icon [icon]="building"></fa-icon
                ><strong> Cape Girardeau, Missouri, USA</strong>
              </li>
              <li>
                <fa-icon [icon]="email"></fa-icon
                ><strong
                  ><a href="mailto:paul@probsolved.dev">
                    paul@probsolved.dev</a
                  ></strong
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-6 col-md-6">
            <ul>
              <!-- <li>
                <fa-icon [icon]="birthday"></fa-icon><strong> 25 ans</strong>
              </li> -->
              <li>
                <fa-icon [icon]="badge"></fa-icon
                ><strong> MS, Information Science</strong>
              </li>
              <li>
                <fa-icon [icon]="home"></fa-icon
                ><strong> CodeLabs, Codefi Foundation </strong>
              </li>
            </ul>
          </div>
        </div>
        <p>
          My PhD work was in historical theology, but that was in another life.
          I am also a former university Dean and Vice-President.
        </p>
      </div>
    </div>
  </div>
</section>
