<!-- Footer -->
<footer class="bg-dark text-center text-white">
  <!-- Grid container -->
  <div class="container p-4">
    <!-- Section: Social media -->
    <section class="mb-4">
      <!-- Facebook -->
      <a
        class="btn btn-outline-light btn-floating m-1"
        href="https://www.facebook.com/probsolved.dev"
        target="_blank"
        role="button"
        ><i class="fa fa-facebook"></i
      ></a>

      <!-- Twitter -->
      <a
        class="btn btn-outline-light btn-floating m-1"
        href="https://www.twitter.com/zee699"
        role="button"
        ><i class="fa fa-twitter"></i
      ></a>

      <!-- Instagram -->
      <!-- <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
        ><i class="fa fa-instagram"></i
      ></a> -->

      <!-- Linkedin -->
      <a
        class="btn btn-outline-light btn-floating m-1"
        href="https://www.linkedin.com/in/paul-roberts-709682190/"
        role="button"
        ><i class="fa fa-linkedin"></i
      ></a>

      <!-- Github -->
      <a
        class="btn btn-outline-light btn-floating m-1"
        href="https://github.com/probsolved"
        role="button"
        ><i class="fa fa-github"></i
      ></a>
    </section>
    <!-- Section: Social media -->

    <!-- Section: Text -->
    <section class="mb-4">
      <p>
        "Data is not information, information is not knowledge, knowledge is not
        understanding, understanding is not wisdom."
      </p>
    </section>
    <!-- Section: Text -->

    <!-- Section: Links -->
    <section class="">
      <!--Grid row-->
      <div class="row">
        <!--Grid column-->
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Policies</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a href="#!" class="text-white">Privacy Policy</a>
            </li>
            <li>
              <a href="#!" class="text-white">Terms of Service</a>
            </li>
            <li>
              <a href="#!" class="text-white">A Promise to My Clients</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Philosophy</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a href="#!" class="text-white">Accessibility</a>
            </li>
            <li>
              <a href="#!" class="text-white">Making a Better Internet</a>
            </li>
            <li>
              <a href="#!" class="text-white">Web Development as Tent-Making</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">My Typical Front-End Tools</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a href="https://angular.io" class="text-white">Angular</a>
            </li>
            <li>
              <a href="https://getbootstrap.com/" class="text-white"
                >Bootstrap</a
              >
            </li>
            <li>
              <a href="https://firebase.google.com/" class="text-white"
                >Firebase Databases</a
              >
            </li>
            <li>
              <a href="https://www.bluehost.com/" class="text-white"
                >Bluehost</a
              >
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Links</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a href="#!" class="text-white">The Codefi Foundation</a>
            </li>
            <li>
              <a href="#!" class="text-white">Code Labs</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </section>
    <!-- Section: Links -->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
    © 2022 Copyright:
    <a class="text-white" href="https://www.probsolved.dev">probsolved.dev</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
