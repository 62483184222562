<section
  id="home"
  [ngStyle]="{ backgroundImage: 'url(assets/images/home-background.jpeg)' }"
  class="d-flex flex-column justify-content-center align-items-center"
>
  <div class="container aos-init aos-animate" data-aos="fade-in">
    <h1 class="mt-3">Paul Roberts</h1>
    <!-- <h2>Web Developer</h2> -->
    <p><span class="typed"></span> Developer</p>
  </div>
</section>
